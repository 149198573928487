import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import {
  FaUserAlt,
  FaLock,
  FaShieldAlt,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa";
import { IoChevronBackCircle } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import LayoutComponent from "../components/shared/Layout";
import AuthContext from "../context/AuthContext";
import { useForm } from "react-hook-form";
import { isEmpty } from "lodash";
import { IoIosArrowBack } from "react-icons/io";
import Lock from "../assets/images/lock.png";
import UserName from "../assets/images/username.png";
import Verfication from "../assets/images/verfication.png"
const Login = () => {

  let hostname = window.location.hostname;
  hostname = hostname.replace(/^www\./, "");
  hostname = hostname.replace(/^ag\./, "");
  hostname = hostname.replace(/^msa\./, "");

let agentLink=hostname=="velkimx.live" || hostname=="heppeni247.live"?"https://allvelkiagentslist.com":"https://velkioriginalagentlist.com";
  let { loginUser, user, loginError } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(true);
  const [getValidateCode, setValidateCode] = useState("");
  const navigate = useNavigate();
  const handleShow = () => {
    setShowPassword(!showPassword);
  };
  const canvasRef = useRef(null);
  const changeCode = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.font = "bold 120px sans-serif";
    const code = Math.floor(1000 + Math.random() * 9000);
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.fillText(code, 0, 130);
    setValidateCode(code);
  };

  useEffect(() => {
    changeCode();
  }, []);

  useEffect(() => {
    if (!isEmpty(user)) {
      navigate("/");
    }
  }, [user]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    unregister,
    reset,
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
      uniqueId: Math.random() * 10000,
    },
  });

  useEffect(() => {
    if (!isEmpty(errors)) {
      changeCode();
    }
  }, [errors]);

  return (
    <div>
      <LayoutComponent>
        <div className="d-flex align-items-center justify-content-start">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              background: "white",
              borderRadius: "50%",
              marginLeft: "8px",
              marginTop: "8px",
            }}
            onClick={() => navigate("/")}
          >
            <IoIosArrowBack size={30} className="fs-1 p-1 cursor-pointer" />
          </div>
        </div>

        <div className="login-log-sec w-100">
          <div className="login-logo">
            <img src="../assets/images/velki-logo.png" alt="" />
          </div>
          <div className="px-2" style={{ position: "relative" }}>
            <span className="login-number"   onClick={() =>  window.location.href=agentLink}></span>
            <Link to={agentLink}>
              {" "}
              <img
                src={hostname=="velkimx.live" || hostname=="heppeni247.live"?"../assets/images/513.png":"../assets/images/356.png"}
                alt=""
              />{" "}
            </Link>
          </div>
          <Form onSubmit={handleSubmit(loginUser)} className="bg-white p-4">
            <label className="mb-3 d-block text-center fs-2">LOGIN</label>
            <Form.Group className="mb-3">
              <FloatingLabel controlId="floatingInput" label="Username">
                <Form.Control
                  {...register("username", {
                    required: "Please enter Username",
                  })}
                  autocomplete="off"
                  type="text"
                  placeholder="Username"
                  style={
                    errors.username
                      ? {
                          borderColor: "red",
                        }
                      : {}
                  }
                />
                {/* <FaUserAlt  /> */}
                <img
                  style={{ width: "17.28px", height: "17.28px", left: "3%" }}
                  src={UserName}
                  className="position-absolute translate-middle-y top-50"
                />
              </FloatingLabel>
              {errors.username && errors?.username?.message && (
                <label className="text-danger">{errors.username.message}</label>
              )}
            </Form.Group>
            <Form.Group className="mb-4">
              <FloatingLabel controlId="floatingPassword" label="Password">
                <Form.Control
                  type={!showPassword ? "text" : "password"}
                  placeholder="Password"
                  maxLength={15}
                  {...register("password", {
                    required: "Please enter password",
                  })}
                  style={
                    errors.password
                      ? {
                          borderColor: "red",
                        }
                      : {}
                  }
                />
                 <img
                  style={{ width: "18px", height: "18px", left: "3%" }}
                  src={Lock}
                  className="position-absolute translate-middle-y top-50"
                />
                {/* <FaLock className="position-absolute translate-middle-y top-50" /> */}
                <Button
                  className="position-absolute translate-middle-y top-50 end-0 me-2 bg-transparent border-0 text-dark p-0 "
                  onClick={() => handleShow()}
                >
                  {!showPassword ? (
                    <FaEye className="opacity-100 fs-5" size="17" />
                  ) : (
                    <FaEyeSlash className="opacity-100 fs-5" size="17" />
                  )}
                </Button>
              </FloatingLabel>
              {errors.password && errors?.password?.message && (
                <label className="text-danger">{errors.password.message}</label>
              )}
            </Form.Group>
            <Form.Group className="mb-4">
              <FloatingLabel controlId="floatingValid" label="ValidationCode">
                <Form.Control
                  type="number"
                  keyboardType="numeric"
                  autocomplete="off"
                  maxLength="4"
                  {...register("validateCode", {
                    required: "Please enter validate code",
                    validate: {
                      validate: (value) =>
                        parseInt(value) === parseInt(getValidateCode) ||
                        "Invalid validate code",
                    },
                  })}
                  style={
                    errors.validateCode
                      ? {
                          borderColor: "red",
                        }
                      : {}
                  }
                  onChange={(e) => {
                    if (e.target.value.length == 4) {
                      e.target.blur();
                      unregister("validateCode", { keepValue: true });
                    }
                  }}
                  placeholder="ValidationCode"
                />
                {/* <FaShieldAlt className="position-absolute translate-middle-y top-50" /> */}
                {/* <img
                  src="../assets/images/canwas.png"
                  alt=""
                  className="position-absolute translate-middle-y top-50 canvas-img"
                /> */}
                   <img
                style={{ width: "18px", height: "18px", left: "3%" }}
                  src={Verfication}
                  className="position-absolute translate-middle-y top-50"
                />
                <canvas
                  ref={canvasRef}
                  onClick={changeCode}
                  className="position-absolute translate-middle-y top-50 canvas-img"
                  id="authenticateImage"
                />
              </FloatingLabel>
              {errors.validateCode && errors?.validateCode?.message && (
                <label className="text-danger">
                  {errors.validateCode.message}
                </label>
              )}
            </Form.Group>
            <div>
              <Form.Check
                type="checkbox"
                id="custom-switch"
                label="Remember me"
              />
            </div>
            {loginError && <label className="text-danger">{loginError}</label>}

            <div className="text-center mt-3">
              <Button type="submit" className="theme-btn ms-auto px-5">
                Login
              </Button>
            </div>
          </Form>
        </div>
      </LayoutComponent>
    </div>
  );
};

export default Login;
