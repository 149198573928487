import React, { useContext } from "react";
import LayoutComponent from "../components/shared/Layout";
import { Link, useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import { BsFillTelephoneForwardFill } from "react-icons/bs";
import AuthContext from "../context/AuthContext";
import { isEmpty } from "lodash";

const UplineWhatsapp = () => {
  const navigate = useNavigate();
  const { profileData } = useContext(AuthContext);
  //  console.log(profileData?.uplineData?.phone,!isEmpty(profileData?.uplineData?.phone),'profileData')
  return (
    <LayoutComponent visibilityType={true}>
      <div className="balance-label position-relative">
        <button className="bg-transparent border-0 text-white position-absolute start-0 pt-0">
          <FaAngleLeft className="fs-3" onClick={() => navigate("/")} />
        </button>
        Upline Whatsapp Number
      </div>

      <div className="p-sm-3 p-2 pt-sm-4 pt-3 inner-sidebar-content">
        <h6 className="fs-6 fw-bold mb-3">WhatsApp Number</h6>
        {/* {profileData?.uplineData?.phone && ( */}
          <div className="bg-white whatsapp-area">
            {/* <Link to={"https://wa.me/15205043356"+profileData?.uplineData?.phone}> */}
            <Link to={"https://wa.me/+15205043356"}>

              {" "}
              <div className="d-flex align-items-center fs-6">
                {" "}
                <img
                  src="../assets/images/casino-icon/whatsapp.png"
                  alt=""
                  className="me-1"
                />{" "}
                +{" "}15205043356
                {/* {profileData?.uplineData?.phone &&
                  profileData?.uplineData?.phone} */}
              </div>{" "}
              <span className="bg-yellow" onClick={() => navigate("https://wa.me/+15205043356")}>
                <BsFillTelephoneForwardFill />
              </span>
            </Link>
          </div>
        {/* )} */}
      </div>
    </LayoutComponent>
  );
};

export default UplineWhatsapp;
